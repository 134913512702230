import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from './bubble.module.scss';

import { Figma, AdobeXD, AfterEffects, ReactLogo, NodeJS, MongoDB, Python, Tensorflow, Gatsby, Chess, Doodling, Videography, ArcticMonkeys, TomRosenthal, Beatles, Feynman, Sherlock, Sapiens, Arrival, Inside, TheBreakfastClub, GrandBudapestHotel, Repertoire } from "../bubble-thumbnail";

const BubbleThumbnail = ({imageSelector}) => {
    switch(imageSelector){
        case 'Figma':
            return <Figma />
        case 'AdobeXD':
            return <AdobeXD />
        case 'After Effects':
            return <AfterEffects />
        case 'React':
            return <ReactLogo />
        case 'NodeJS':
            return <NodeJS />
        case 'MongoDB':
            return <MongoDB />
        case 'Python':
            return <Python />
        case 'Tensorflow':
            return <Tensorflow />
        case 'Gatsby':
            return <Gatsby />
        case 'Chess':
            return <Chess />
        case 'Doodling':
            return <Doodling />
        case 'Videography':
            return <Videography />
        case 'ArcticMonkeys':
            return <ArcticMonkeys />
        case 'TomRosenthal':
            return <TomRosenthal />
        case 'Beatles':
            return <Beatles />
        case 'Feynman':
            return <Feynman />
        case 'Sherlock':
            return <Sherlock />
        case 'Sapiens':
            return <Sapiens />
        case 'Arrival':
            return <Arrival />
        case 'Inside':
            return <Inside />
        case 'TheBreakfastClub':
            return <TheBreakfastClub />
        case 'GrandBudapestHotel':
            return <GrandBudapestHotel />
        case 'Repertoire':
            return <Repertoire />
        default:
            return null;
    }
}

const Bubble = ({ imageSelector, title, subtitle, link, color }) => (
    <div className={style.card} style={{ background: color ? color : '#DDD'}}>
        { link ? 
        <Link to={link}>
            <div className={style.thumbnail}>                
                <div className={style.image}>
                    <BubbleThumbnail imageSelector={imageSelector} />
                </div>
            </div>
            <div className={style.information}>
                {title ? <h5 className={`add-margin-bottom-xs`}>{title}</h5> : null}
                {subtitle ? <p className={`primary`}>{subtitle}</p> : null}
            </div>
        </Link> : 
            <>
            <div className={style.thumbnail}>                
                <div className={style.image}>
                    <BubbleThumbnail imageSelector={imageSelector} />
                </div>
            </div>
            <div className={style.information}>
                {title ? <h5 className={`add-margin-bottom-xs`}>{title}</h5> : null}
                {subtitle ? <p className={`primary`}>{subtitle}</p> : null}
            </div>
            </>
        }
    </div>
)

Bubble.propTypes = {
    imageSelector: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
}

Bubble.defaultProps = {
    imageSelector: ``,
    link: null,
}

export default Bubble