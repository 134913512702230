import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from './bubble-image.module.scss';

import { Colorado, Atlanta, Delhi, Orlando, NewOrleans, Jodhpur, Mumbai, Goa, Muenster, Agra } from "../bubble-image-thumbnail";
import { Sapiens, Sherlock, Feynman, Beatles, TomRosenthal, ArcticMonkeys, Arrival, Inside, TheBreakfastClub, GrandBudapestHotel, Repertoire } from "../bubble-thumbnail";

const BubbleImageThumbnail = ({imageSelector}) => {
    switch(imageSelector){
        case 'Colorado':
            return <Colorado />
        case 'Atlanta':
            return <Atlanta />
        case 'Delhi':
            return <Delhi />
        case 'Orlando':
            return <Orlando />
        case 'NewOrleans':
            return <NewOrleans />
        case 'Agra':
            return <Agra />
        case 'Jodhpur':
            return <Jodhpur />
        case 'Mumbai':
            return <Mumbai />
        case 'Goa':
            return <Goa />
        case 'Muenster':
            return <Muenster />
        case 'ArcticMonkeys':
            return <ArcticMonkeys />
        case 'TomRosenthal':
            return <TomRosenthal />
        case 'Beatles':
            return <Beatles />
        case 'Feynman':
            return <Feynman />
        case 'Sherlock':
            return <Sherlock />
        case 'Sapiens':
            return <Sapiens />
        case 'Arrival':
            return <Arrival />
        case 'Inside':
            return <Inside />
        case 'TheBreakfastClub':
            return <TheBreakfastClub />
        case 'GrandBudapestHotel':
            return <GrandBudapestHotel />
        case 'Repertoire':
            return <Repertoire />
        default:
            return null;
    }
}

const BubbleImage = ({ imageSelector, title, subtitle, link, color }) => (
    <div className={style.card} style={{ background: color ? color : '#DDD'}}>
        {link ? <Link to={link}>
            <div className={style.thumbnail}>                
                <div className={style.image}>
                    <BubbleImageThumbnail imageSelector={imageSelector} />
                </div>
            </div>
            <div className={style.information}>
                {title ? <h5 className={`add-margin-bottom-xs`}>{title}</h5> : null}
                {subtitle ? <p className={`primary`}>{subtitle}</p> : null}
            </div>
        </Link> :
            <>
            <div className={style.thumbnail}>                
                <div className={style.image}>
                    <BubbleImageThumbnail imageSelector={imageSelector} />
                </div>
            </div>
            <div className={style.information}>
                {title ? <h5 className={`add-margin-bottom-xs`}>{title}</h5> : null}
                {subtitle ? <p className={`primary`}>{subtitle}</p> : null}
            </div>
            </>
        }
    </div>
)

BubbleImage.propTypes = {
    imageSelector: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
}

BubbleImage.defaultProps = {
    imageSelector: ``,
    link: null,
}

export default BubbleImage