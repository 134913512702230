import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import BubbleImage from "../../bubble-image/bubble-image";

const AboutTravelCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 8 - 1rem)`}}>
        <BubbleImage imageSelector={`Colorado`} title={`Colorado`} subtitle={`US, 2021`} color={`#173A6D`}/>
        <BubbleImage imageSelector={`Atlanta`} title={`Atlanta`} subtitle={`US, 2021`} color={`#6D4B17`}/>
        <BubbleImage imageSelector={`Delhi`} title={`Delhi`} subtitle={`IN, Home`} color={`#36201B`}/>
        <BubbleImage imageSelector={`Orlando`} title={`Orlando`} subtitle={`US, 2019`} color={`#36188D`}/>
        <BubbleImage imageSelector={`NewOrleans`} title={`New Orleans`} subtitle={`US, 2019`} color={`#63183C`}/>
        <BubbleImage imageSelector={`Agra`} title={`Agra`} subtitle={`IN, 2018`} color={`#655E54`}/>
        <BubbleImage imageSelector={`Jodhpur`} title={`Jodhpur`} subtitle={`IN, 2018`} color={`#4E3625`}/>
        <BubbleImage imageSelector={`Muenster`} title={`Muenster`} subtitle={`DE, 2012`} color={`#29354E`}/>
    </div>
    </div>
    </>
)

export default AboutTravelCards