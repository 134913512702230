import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Figma = () => (
    <StaticImage
        src={'../images/about/tools/Figma.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const AdobeXD = () => (
    <StaticImage
        src={'../images/about/tools/Adobe XD.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Adobe XD"
        loading={`eager`}
    />
)

const AfterEffects = () => (
    <StaticImage
        src={'../images/about/tools/After Effects.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const ReactLogo = () => (
    <StaticImage
        src={'../images/about/languages/React.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const NodeJS = () => (
    <StaticImage
        src={'../images/about/languages/NodeJS.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const MongoDB = () => (
    <StaticImage
        src={'../images/about/languages/Mongo.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Python = () => (
    <StaticImage
        src={'../images/about/languages/Python.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Tensorflow = () => (
    <StaticImage
        src={'../images/about/languages/Tensorflow.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Gatsby = () => (
    <StaticImage
        src={'../images/about/languages/Gatsby.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

/* Favorites */

const Chess = () => (
    <StaticImage
        src={'../images/about/favorites/Chess.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Doodling = () => (
    <StaticImage
        src={'../images/about/favorites/Doodling.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Videography = () => (
    <StaticImage
        src={'../images/about/favorites/Videography.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const TomRosenthal = () => (
    <StaticImage
        src={'../images/about/favorites/Tom Rosenthal.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const ArcticMonkeys = () => (
    <StaticImage
        src={'../images/about/favorites/Arctic Monkeys.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Beatles = () => (
    <StaticImage
        src={'../images/about/favorites/Beatles.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Feynman = () => (
    <StaticImage
        src={'../images/about/favorites/Feynman.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Sherlock = () => (
    <StaticImage
        src={'../images/about/favorites/Sherlock.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Sapiens = () => (
    <StaticImage
        src={'../images/about/favorites/Sapiens.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Arrival = () => (
    <StaticImage
        src={'../images/about/favorites/Arrival.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Inside = () => (
    <StaticImage
        src={'../images/about/favorites/Inside.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const Repertoire = () => (
    <StaticImage
        src={'../images/about/favorites/Repertoire.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const TheBreakfastClub = () => (
    <StaticImage
        src={'../images/about/favorites/The Breakfast Club.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

const GrandBudapestHotel = () => (
    <StaticImage
        src={'../images/about/favorites/Grand Budapest Hotel.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="After Effects"
        loading={`eager`}
    />
)

export {Figma, AdobeXD, AfterEffects, ReactLogo, NodeJS, MongoDB, Python, Tensorflow, Gatsby, Chess, Doodling, Videography, TomRosenthal, ArcticMonkeys, Beatles, Sherlock, Sapiens, Feynman, Arrival, Inside, Repertoire, TheBreakfastClub, GrandBudapestHotel};