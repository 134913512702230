import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import Bubble from "../../bubble/bubble";

const AboutDesignCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 3 - 1rem)`}}>
        <Bubble imageSelector={`Figma`} title={`Figma`} subtitle={`5+ years`} color={`#222`}/>
        <Bubble imageSelector={`AdobeXD`} title={`Adobe XD`} subtitle={`2+ years`} color={`#5F2353`} />
        <Bubble imageSelector={`After Effects`} title={`After Effects`} subtitle={`2+ years`} color={`#37378B`} />
    </div>
    </div>
    </>
)

export default AboutDesignCards