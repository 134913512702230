import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import BubbleImage from "../../bubble-image/bubble-image"

const AboutBooksCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 3 - 1rem)`}}>
        <BubbleImage imageSelector={`Feynman`} title={`Surely You're Joking, Mr. Feynman`} color={`#4C3F61`}/>
        <BubbleImage imageSelector={`Sherlock`} title={`The Adventures of Sherlock Holmes`} color={`#72411B`}/>
        <BubbleImage imageSelector={`Sapiens`} title={`Sapiens: A Brief History of Humankind`} color={`#938575`}/>
    </div>
    </div>
    </>
)

export default AboutBooksCards