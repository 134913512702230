import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Colorado = () => (
    <StaticImage
        src={'../images/about/travel/Colorado.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Atlanta = () => (
    <StaticImage
        src={'../images/about/travel/Atlanta.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Delhi = () => (
    <StaticImage
        src={'../images/about/travel/Delhi.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Orlando = () => (
    <StaticImage
        src={'../images/about/travel/Orlando.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const NewOrleans = () => (
    <StaticImage
        src={'../images/about/travel/New Orleans.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Jodhpur = () => (
    <StaticImage
        src={'../images/about/travel/Jodhpur.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Agra = () => (
    <StaticImage
        src={'../images/about/travel/Agra.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Mumbai = () => (
    <StaticImage
        src={'../images/about/travel/Mumbai.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Goa = () => (
    <StaticImage
        src={'../images/about/travel/Goa.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

const Muenster = () => (
    <StaticImage
        src={'../images/about/travel/Muenster.png'}
        width={200}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Figma"
        loading={`eager`}
    />
)

export { Colorado, Atlanta, Delhi, Muenster, Mumbai, Goa, Jodhpur, NewOrleans, Orlando, Agra };