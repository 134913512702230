import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import Bubble from "../../bubble/bubble";
import BubbleImage from "../../bubble-image/bubble-image";

const AboutMoviesCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 5 - 1rem)`}}>
        <BubbleImage imageSelector={`Arrival`} title={`Arrival`} color={`#263A4B`}/>
        <BubbleImage imageSelector={`TheBreakfastClub`} title={`The Breakfast Club`} color={`#48413F`}/>
        <BubbleImage imageSelector={`Inside`} title={`Inside`} subtitle={`Bo Burnham`} color={`#6C4323`}/>
        <BubbleImage imageSelector={`GrandBudapestHotel`} title={`The Grand Budapest Hotel`} color={`#BF5781`}/>
        <BubbleImage imageSelector={`Repertoire`} title={`Repertoire`} subtitle={`James Acaster`} color={`#843A3A`}/>
    </div>
    </div>
    </>
)

export default AboutMoviesCards