import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import Bubble from "../../bubble/bubble";

const AboutDevCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 6 - 1rem)`}}>
        <Bubble imageSelector={`React`} title={`React`} subtitle={`5+ years`} color={`#022634`}/>
        <Bubble imageSelector={`NodeJS`} title={`Node`} subtitle={`4+ years`} color={`#35540D`} />
        <Bubble imageSelector={`MongoDB`} title={`MongoDB`} subtitle={`4+ years`} color={`#0F2403`} />
        <Bubble imageSelector={`Python`} title={`Python`} subtitle={`5+ years`} color={`#001225`} />
        <Bubble imageSelector={`Tensorflow`} title={`Tensorflow`} subtitle={`4+ years`} color={`#3D261E`} />
        <Bubble imageSelector={`Gatsby`} title={`Gatsby`} subtitle={`2+ years`} color={`#2C0B50`} />
    </div>
    </div>
    </>
)

export default AboutDevCards