import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

import { RoughNotation } from "react-rough-notation"
import { StaticImage } from "gatsby-plugin-image"

import PDF from '../static/Resume.pdf'

import AboutDesignCards from "../components/about/cards/design-cards"
import AboutDevCards from "../components/about/cards/dev-cards"
import AboutTravelCards from "../components/about/cards/travel-cards"
import AboutLeisureCards from "../components/about/cards/leisure-cards"
import AboutMoviesCards from "../components/about/cards/movies-cards"
import AboutMusicCards from "../components/about/cards/music-cards"
import AboutBooksCards from "../components/about/cards/books-cards"

const AboutPage = () => (
  <>
    <Seo title="About" />
    <section className={`about-section fix-header`}>
      <div className={`content`}>
        <div className={`content--md-center add-margin-bottom`}>
          <h6 className={`primary center`}>About</h6>
          <h1 className={`center`}>An Introduction</h1>
          <p className={`lg primary center`}>I'm Abhinav Thukral, a 24 year old who loves design, development, music, doodles, astronomy, chocolates, physics, and puzzles.</p>
        </div>        
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content`}>
        <div className={`split-wrapper add-margin-top`}>
          <div className={`split`}>
            <div className={`photo add-margin-top-sm`}>
              <StaticImage src={`../images/me.jpg`} />
            </div>
          </div>
          <div className={`split`}>
              {/* <p className={`primary lh`}>Hey there! I'm Abhinav Thukral, a 23 year old who loves design, development, music, doodles, astronomy, chocolates, physics, and mathematics.</p> */}
              <p className={`primary lh lg`}>Currently, I'm pursuing my Masters in Human-Computer Interaction at <a href="https://mshci.gatech.edu" target="_blank"><span className={`link data-hover`}>Georgia Tech <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></span></a>. Previously, I have worked with organizations like EPFL, Mastercard, Wingify, Bioversity International contributing to UI/UX engineering, interaction design, full-stack development, machine learning, and videography.</p>
              <p className={`primary lh lg`}>On weekends, I serve as the co-founder of a social initiative — <a href="http://weclothethem.com/" target="_blank"><span className={`link data-hover`}>We Clothe Them <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></span></a>, that aims to empower people by providing them access to clothing.</p>
              <a href={PDF} target={`_blank`}><button className={`data-hover`}>View Resume <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></button></a>
          </div>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content`}>
        <div className={`split-wrapper`}>
            <div>
              <h3 className={``}>Design Tools</h3>
              <div className={`content--left`}>
                <p>Tools I've been using for all my design work</p>
              </div>
            </div>
            <AboutDesignCards />
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content`}>
        <div className={`split-wrapper`}>
          <div>
              <h3 className={``}>Development</h3>
              <div className={`content--left`}>
                <p>Languages/Frameworks that I code with to build new solutions</p>
              </div>
            </div>
          <AboutDevCards />
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content`}>
        <div className={`split-wrapper`}>
          <div>
              <h3 className={``}>Travel/Life</h3>
              <div className={`content--left`}>
                <p>Exploring new places, one step at time</p>
              </div>
            </div>
          <AboutTravelCards />
        </div>
      </div>
    </section>
    <section className={`fix-top`}>
      <div className={`content`}>
        <h3 className={`add-margin-bottom`}>Personal Favorites</h3>
        <div className={`split-wrapper add-margin-bottom`}>
          <div>
              <h4 className={``}>Leisure</h4>
              <div className={`content--left`}>
                <p>From leisure to spending hours on these interests</p>
              </div>
            </div>
          <AboutLeisureCards />
        </div>
        <div className={`split-wrapper add-margin-bottom`}>
          <div>
              <h4 className={``}>Movies/TV</h4>
              <div className={`content--left`}>
                <p>I’ll watch anything on this list a hundred times over</p>
              </div>
            </div>
          <AboutMoviesCards />
        </div>
        <div className={`split-wrapper add-margin-bottom`}>
          <div>
              <h4 className={``}>Music</h4>
              <div className={`content--left`}>
                <p>Favorite artists that are playing in my head when I can't sleep</p>
              </div>
            </div>
          <AboutMusicCards />
        </div>
        <div className={`split-wrapper`}>
          <div>
              <h4 className={``}>Books</h4>
              <div className={`content--left`}>
                <p>Books I often refer back to whenever I'm bored</p>
              </div>
            </div>
          <AboutBooksCards />
        </div>
      </div>
    </section>
  </>
)

export default AboutPage
