import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import BubbleImage from "../../bubble-image/bubble-image"

const AboutMusicCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 3 - 1rem)`}}>
        <BubbleImage imageSelector={`ArcticMonkeys`} title={`Arctic Monkeys`} color={`#242728`}/>
        <BubbleImage imageSelector={`TomRosenthal`} title={`Tom Rosenthal`} color={`#173642`}/>
        <BubbleImage imageSelector={`Beatles`} title={`Beatles`} color={`#783030`}/>
    </div>
    </div>
    </>
)

export default AboutMusicCards