import * as React from "react"

import { Link } from "gatsby"
// import * as style from './design-cards.module.scss';

import Bubble from "../../bubble/bubble"

const AboutLeisureCards = () => (
    <>
    <div className={`overflow-wrapper`}>
    <div className={`about-tiles-wrapper`} style={{width: `calc(13rem * 3 - 1rem)`}}>
        <Bubble imageSelector={`Chess`} title={`Chess`} color={`#2B3B27`}/>
        <Bubble imageSelector={`Doodling`} title={`Doodling`} color={`#793F5B`}/>
        <Bubble imageSelector={`Videography`} title={`Photography / Videography`} color={`#1D1F23`}/>
    </div>
    </div>
    </>
)

export default AboutLeisureCards